import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/Header and Footer/SearchResultsPage.css';

// FontAwesome / Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faHeart } from '@fortawesome/free-solid-svg-icons';

// JWT decode (if the token contains userId and securityCode)
import { jwtDecode } from 'jwt-decode';

interface Category {
  id: number;
  name: string;
  description: string;
  genre: string;
  author?: string;
  price?: number;
  isbn?: string;
  imageUrl?: string;
  condition?: string; // If needed
  // Add other relevant fields as needed
}

const SearchResultsPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const location = useLocation();

  // Extract 'searchTerm' from the URL query parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const termParam = params.get('searchTerm') || '';
    setSearchTerm(termParam);
  }, [location.search]);

  // Helper: decode token to get userId and securityCode
  const getUserDataFromToken = (): { userId: string | null; securityCode: string | null } => {
    const token = localStorage.getItem('jwtToken');
    if (!token) return { userId: null, securityCode: null };

    try {
      const decodedToken = jwtDecode<{ userId: string; securityCode: string }>(token);
      return {
        userId: decodedToken.userId,
        securityCode: decodedToken.securityCode,
      };
    } catch (err) {
      console.error('Failed to decode token:', err);
      return { userId: null, securityCode: null };
    }
  };

  // Add to Cart function
  const addToCart = async (productId: number) => {
    const { userId, securityCode } = getUserDataFromToken();
    const quantity = 1;

    if (!userId || !securityCode) {
      toast.error('User is not logged in or product ID is missing.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/Cart/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: JSON.stringify({ userId, productId, quantity, securityCode }),
      });

      if (!response.ok) {
        throw new Error(`Failed to add to cart. Status: ${response.status}`);
      }

      toast.success('Added to cart successfully!');
    } catch (err) {
      console.error('Error adding to cart:', err);
      toast.error('Failed to add to cart.');
    }
  };

  // Add to Wishlist function
  const addToWishlist = async (productId: number) => {
    const { userId, securityCode } = getUserDataFromToken();

    if (!userId || !securityCode) {
      toast.error('User is not logged in or product ID is missing.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/Wishlist/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: JSON.stringify({ userId, productId, securityCode }),
      });

      if (!response.ok) {
        throw new Error(`Failed to add to wishlist. Status: ${response.status}`);
      }

      toast.success('Added to wishlist successfully!');
    } catch (err) {
      console.error('Error adding to wishlist:', err);
      toast.error('Failed to add to wishlist.');
    }
  };

  // Fetch from your advanced search endpoint
  useEffect(() => {
    const fetchSearchResults = async () => {
      if (!searchTerm) {
        // If there's no searchTerm, do nothing or show a prompt
        setResults([]);
        setError(null);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        // Endpoint: /api/Category/search-by-isbn-or-title?searchTerm=...
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/Category/search-by-isbn-or-title?searchTerm=${encodeURIComponent(
          searchTerm
        )}`;

        const response = await fetch(url);

        if (!response.ok) {
          // If no results, your endpoint might return 404
          if (response.status === 404) {
            setResults([]);
            setError(`No results found for "${searchTerm}"`);
          } else {
            throw new Error(`Server returned status: ${response.status}`);
          }
        } else {
          const data: Category[] = await response.json();
          setResults(data);
        }
      } catch (err: any) {
        setError(err.message || 'An unexpected error occurred.');
        setResults([]);
      } finally {
        setLoading(false);
      }
    };

    fetchSearchResults();
  }, [searchTerm]);

  return (
    <>
      <Header />
      <ToastContainer />
      <div className="search-results-page-container">
        {loading && (
          <div className="search-results-loading-container">
            <div className="search-results-loading-spinner"></div>
            <p>Searching...</p>
          </div>
        )}

        {!loading && (
          <>
            <h1 className="search-results-title">Search Results</h1>
            {error && <p className="search-results-error">{error}</p>}

            {!error && results.length === 0 && searchTerm && (
              <p className="search-results-no-match">
                No results found for <strong>{searchTerm}</strong>. Try another search.
              </p>
            )}

            <div className="search-results-list">
              {results.map((item) => (
                <div key={item.id} className="search-results-item">
                  <h3 className="search-results-item-name">{item.name}</h3>
                  {item.imageUrl && (
                    <img
                      src={
                        item.imageUrl.startsWith('/')
                          ? `${process.env.REACT_APP_API_BASE_URL}${item.imageUrl}`
                          : item.imageUrl
                      }
                      alt={item.name}
                      className="search-results-item-image"
                      onError={(e) => {
                        e.currentTarget.src =
                          'https://via.placeholder.com/400x200?text=No+Image+Available';
                        e.currentTarget.alt = 'Placeholder image';
                      }}
                    />
                  )}
                  <p className="search-results-item-description">{item.description}</p>
                  <p>
                    <strong>Genre:</strong> {item.genre}
                  </p>
                  {item.author && (
                    <p>
                      <strong>Author:</strong> {item.author}
                    </p>
                  )}
                  {item.isbn && (
                    <p>
                      <strong>ISBN:</strong> {item.isbn}
                    </p>
                  )}
                  {item.price !== undefined && (
                    <p>
                      <strong>Price:</strong> R{item.price}
                    </p>
                  )}

                  {/* --- Add to Cart & Wishlist Buttons --- */}
                  <div className="search-results-buttons">
                    <button
                      className="search-results-add-cart"
                      onClick={() => addToCart(item.id)}
                    >
                      Add to Cart <FontAwesomeIcon icon={faShoppingCart} />
                    </button>

                    <button
                      className="search-results-add-wishlist"
                      onClick={() => addToWishlist(item.id)}
                    >
                      Add to Wishlist <FontAwesomeIcon icon={faHeart} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default SearchResultsPage;
