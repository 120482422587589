import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../style/Admin/Orders/ProcessOrder.css';
import Header from '../../Header and Footer/Header';
import Footer from '../../Header and Footer/Footer';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import { jwtDecode } from "jwt-decode";

interface Payment {
  id: number;
  paymentReference: string;
}

interface User {
  id: number;
  email: string;
  username: string;
  fullName: string;
  cellphone: string;
}

interface InterimCheckoutPart2 {
  shippingMethodId: number;
  itemIds: string;
  addressId: number;
}

interface PaymentDetails {
  totalPrice: number;
  shippingPrice: number;
  user: User;
  interimCheckoutPart2: InterimCheckoutPart2;
  notes?: string;
}

interface DecodedToken {
  userId: string;
  isAdmin: string; // "True" or "False" in your JWT
  [key: string]: any;
}

// Function to get admin userId and isAdmin from the JWT token
const getAdminUserInfoFromToken = (): { userId: string | null; isAdmin: boolean } => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return { userId: null, isAdmin: false };

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    const isAdmin = decodedToken.isAdmin === "True"; // Convert "True" or "False" to boolean
    return { userId: decodedToken.userId, isAdmin };
  } catch (error) {
    console.error('Failed to decode token:', error);
    return { userId: null, isAdmin: false };
  }
};

const ProcessOrder: React.FC = () => {
  const [paidPayments, setPaidPayments] = useState<Payment[]>([]);
  const [expandedPaymentId, setExpandedPaymentId] = useState<number | null>(null);
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [sellerId, setSellerId] = useState<number | null>(null);
  const navigate = useNavigate();

  // Get admin userId and isAdmin flag from token
  const { userId: adminUserId, isAdmin } = getAdminUserInfoFromToken();

  // Redirect non-admin users
  useEffect(() => {
    if (!isAdmin) {
      toast.error('You do not have permission to access this page.');
      navigate('/not-authorized');
    }
  }, [isAdmin, navigate]);

  // Fetch paid payments if admin
  useEffect(() => {
    if (isAdmin) {
      fetchPaidPayments();
    }
  }, [isAdmin]);

  const fetchPaidPayments = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        toast.error('Authentication token not found. Please log in.');
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/ManageOrder/paid-payments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setPaidPayments(response.data);
    } catch (error) {
      toast.error('Failed to fetch payments.');
      console.error('Error details:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSellerId = async (shippingMethodId: number) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Shipping/seller/${shippingMethodId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setSellerId(response.data.sellerId);
    } catch (error) {
      console.error('Error fetching seller ID:', error);
      toast.error('Failed to fetch seller ID.');
    }
  };

  const fetchPaymentDetails = async (paymentId: number) => {
    setDetailsLoading(true);
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/ManageOrder/payment-details/${paymentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setPaymentDetails(response.data);
      fetchSellerId(response.data.interimCheckoutPart2.shippingMethodId);
    } catch (error) {
      console.error('Error fetching payment details:', error);
      toast.error('Failed to fetch payment details.');
    } finally {
      setDetailsLoading(false);
    }
  };

  const handleRowClick = (paymentId: number) => {
    if (expandedPaymentId === paymentId) {
      setExpandedPaymentId(null);
      setPaymentDetails(null);
    } else {
      setExpandedPaymentId(paymentId);
      fetchPaymentDetails(paymentId);
    }
  };

  const changePaymentStatus = async (
    paymentId: number,
    newStatus: string,
    comments: string
  ): Promise<void> => {
    try {
      if (!adminUserId) {
        toast.error('Admin user ID not found. Please log in.');
        return;
      }
      const token = localStorage.getItem('jwtToken');

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Payment/change-payment-status`,
        null, // Pass `null` for the body, since we're using query parameters
        {
          params: {
            paymentId,
            adminUserId,
            newStatus,
            comments,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      toast.success(`Payment status updated to "${newStatus}".`);
    } catch (error) {
      console.error('Error changing payment status:', error);
      toast.error('Failed to update payment status.');
    }
  };

  const handleProcessOrder = async () => {
    if (!paymentDetails || !expandedPaymentId || sellerId === null) return;

    const fulfilOrderRequest = {
      paymentId: expandedPaymentId,
      userId: paymentDetails.user.id,
      totalPrice: paymentDetails.totalPrice,
      shippingPrice: paymentDetails.shippingPrice,
      email: paymentDetails.user.email,
      fullName: paymentDetails.user.fullName,
      cellphone: paymentDetails.user.cellphone,
      addressId: paymentDetails.interimCheckoutPart2.addressId,
      shippingMethodId: paymentDetails.interimCheckoutPart2.shippingMethodId,
      sellerId: sellerId,
      itemIds: paymentDetails.interimCheckoutPart2.itemIds,
      notes: paymentDetails.notes,
    };

    try {
      const token = localStorage.getItem('jwtToken');

      // Process the order
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/FulfilOrder`,
        fulfilOrderRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Change payment status to "Processed"
      await changePaymentStatus(
        expandedPaymentId,
        'Processed',
        'Order processed successfully.'
      );

      // Update the UI
      setPaidPayments((prevPayments) =>
        prevPayments.filter((payment) => payment.id !== expandedPaymentId)
      );
      setExpandedPaymentId(null);
      setPaymentDetails(null);
      toast.success('Order processed successfully.');
    } catch (error) {
      console.error('Unexpected error:', error);
      toast.error('Failed to process the order.');
    }
  };

  // Conditional rendering after Hooks
  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Header />
      <div className="process-order-page">
        <div className="container">
          <h1 className="page-title">Paid Payments</h1>
          {loading ? (
            <div className="loading-container">
              <ClipLoader color="#4A90E2" loading={loading} size={50} />
              <p>Loading payments...</p>
            </div>
          ) : paidPayments.length > 0 ? (
            <div className="payments-table-wrapper">
              <table className="payments-table">
                <thead>
                  <tr>
                    <th>Payment ID</th>
                    <th>Payment Reference</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {paidPayments.map((payment) => (
                    <React.Fragment key={payment.id}>
                      <tr
                        onClick={() => handleRowClick(payment.id)}
                        className={`payment-row ${expandedPaymentId === payment.id ? 'expanded' : ''}`}
                      >
                        <td>{payment.id}</td>
                        <td>{payment.paymentReference}</td>
                        <td className="action-cell">
                          {expandedPaymentId === payment.id ? (
                            <FaChevronUp />
                          ) : (
                            <FaChevronDown />
                          )}
                        </td>
                      </tr>
                      {expandedPaymentId === payment.id && (
                        <tr className="payment-details-row">
                          <td colSpan={3}>
                            {detailsLoading ? (
                              <div className="details-loading">
                                <ClipLoader color="#4A90E2" loading={detailsLoading} size={30} />
                                <p>Loading details...</p>
                              </div>
                            ) : paymentDetails ? (
                              <div className="payment-details">
                                <div className="details-section">
                                  <h2>Summary</h2>
                                  <p>
                                    <strong>Total Price:</strong> R
                                    {paymentDetails.totalPrice.toFixed(2)}
                                  </p>
                                  <p>
                                    <strong>Shipping Price:</strong> R
                                    {paymentDetails.shippingPrice.toFixed(2)}
                                  </p>
                                </div>
                                <div className="details-section">
                                  <h2>User Information</h2>
                                  <p>
                                    <strong>User ID:</strong>{' '}
                                    {paymentDetails.user.id}
                                  </p>
                                  <p>
                                    <strong>Email:</strong>{' '}
                                    {paymentDetails.user.email}
                                  </p>
                                  <p>
                                    <strong>Username:</strong>{' '}
                                    {paymentDetails.user.username}
                                  </p>
                                  <p>
                                    <strong>Full Name:</strong>{' '}
                                    {paymentDetails.user.fullName}
                                  </p>
                                  <p>
                                    <strong>Cellphone:</strong>{' '}
                                    {paymentDetails.user.cellphone}
                                  </p>
                                </div>
                                <div className="details-section">
                                  <h2>Shipping Information</h2>
                                  <p>
                                    <strong>Address ID:</strong>{' '}
                                    {
                                      paymentDetails.interimCheckoutPart2
                                        .addressId
                                    }
                                  </p>
                                  <p>
                                    <strong>Shipping Method ID:</strong>{' '}
                                    {
                                      paymentDetails.interimCheckoutPart2
                                        .shippingMethodId
                                    }
                                  </p>
                                  <p>
                                    <strong>Item IDs:</strong>{' '}
                                    {paymentDetails.interimCheckoutPart2.itemIds}
                                  </p>
                                </div>
                                {/* Important Notes Section */}
                                {paymentDetails.notes && (
                                  <div className="important-notes">
                                    <h2>Important Note</h2>
                                    <p>{paymentDetails.notes}</p>
                                  </div>
                                )}
                                <div className="process-button-container">
                                  <button
                                    className="process-button"
                                    onClick={handleProcessOrder}
                                  >
                                    Process Order
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <p className="no-details">No details available.</p>
                            )}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="no-payments">No paid payments found.</p>
          )}
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
};

export default ProcessOrder;
