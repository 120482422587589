import React, { useState } from 'react';
import '../../style/Register/Login.css';
import { useNavigate, Link } from 'react-router-dom';

interface FormData {
  username: string;
  password: string;
}

interface DecodedToken {
  userId: string;
  isBusinessActivated: string;
  role: string;
  [key: string]: any;
}

// Input sanitization function
const sanitizeInput = (input: string): string => {
  // Remove leading and trailing whitespace
  let sanitizedInput = input.trim();
  // Remove any HTML tags
  sanitizedInput = sanitizedInput.replace(/<[^>]*>/g, '');
  return sanitizedInput;
};

const Login: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [isLoading, setIsLoading] = useState(false); // <-- New state for loading
  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData: FormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevErrors: Partial<FormData>) => ({
      ...prevErrors,
      [name]: '', // Clear error when user starts typing
    }));
  };

  // Validate form inputs
  const validateForm = () => {
    const newErrors: Partial<FormData> = {};

    if (!formData.username) {
      newErrors.username = 'Username is required';
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true); // <-- Start loading
      try {
        // Sanitize inputs
        const sanitizedUsername = sanitizeInput(formData.username);
        const sanitizedPassword = sanitizeInput(formData.password);

        const usernameWithAt = sanitizedUsername.startsWith('@')
          ? sanitizedUsername
          : `@${sanitizedUsername}`;

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/Auth/login`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: usernameWithAt,
              password: sanitizedPassword,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          const token = data.token;

          // Store the JWT token in local storage
          localStorage.setItem('jwtToken', token);

          console.log('Login successful.');
          // Redirect to landing page
          navigate('/landingpage');
        } else {
          const errorMessage = await response.text();
          console.error('Login failed. Server response:', errorMessage);
          setErrors({ password: 'Invalid username or password' });
        }
      } catch (error) {
        console.error('Error during login:', error);
        setErrors({ password: 'An error occurred during login' });
      } finally {
        setIsLoading(false); // <-- Stop loading
      }
    }
  };

  return (
    <div className="login-page">
      <div className="login-form-container">
        <div className="login-heading">
          <h2>Login</h2>
          <p>Enter your credentials to access your account</p>
        </div>
        <form onSubmit={handleSubmit} className="login-form">
          {/* Username */}
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <div className="username-input">
              <span>@</span>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="Enter username"
                className={errors.username ? 'error-input' : ''}
              />
            </div>
            {errors.username && <span className="error-message">{errors.username}</span>}
          </div>

          {/* Password */}
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter password"
              className={errors.password ? 'error-input' : ''}
            />
            {errors.password && <span className="error-message">{errors.password}</span>}
          </div>

          {/* Form Footer */}
          <div className="login-footer">
            <p>
              Don't have an account?{' '}
              <Link to="/register" className="register-link">
                Register
              </Link>{' '}
              here
            </p>
            
            <p>
              <Link to="/forgot-password" className="forgot-password-link">
                Forgot password?
              </Link>
            </p>
            
            <button type="submit" className="login-button" disabled={isLoading}>
              {isLoading ? (
                <>
                  <span className="loading-spinner" />
                  Verifying...
                </>
              ) : (
                'Login'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
