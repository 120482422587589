import React, { useState, useEffect } from 'react';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/Profile/Profile.css';
import { Edit2, Save, Camera, Briefcase, Clock, Shield } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { jwtDecode } from "jwt-decode";

interface UserProfile {
  id: number;
  email: string;
  username: string;
  fullName: string | null;
  bio: string | null;
  cellphone: string | null;
  profilePic: string | null;
}

interface DecodedToken {
  userId: string;
  isAdmin: string;            // "True" or "False" in your JWT
  isBusinessActivated: string; // "Yes" or "No" in your JWT
  [key: string]: any;
}

// Function to get userId, isAdmin, and isBusinessActivated from the JWT token
const getUserInfoFromToken = (): {
  userId: string | null;
  isAdmin: boolean;
  isBusinessActivated: string | null;
} => {
  const token = localStorage.getItem('jwtToken');
  if (!token) {
    return { userId: null, isAdmin: false, isBusinessActivated: null };
  }

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    // Convert "True"/"False" string into a proper boolean
    const isAdmin = decodedToken.isAdmin === 'True';
    const isBusinessActivated = decodedToken.isBusinessActivated;
    return {
      userId: decodedToken.userId,
      isAdmin,
      isBusinessActivated,
    };
  } catch (error) {
    console.error('Failed to decode token:', error);
    return { userId: null, isAdmin: false, isBusinessActivated: null };
  }
};

const Profile: React.FC = () => {
  const [user, setUser] = useState<UserProfile | null>(null);
  const [profilePicUrl, setProfilePicUrl] = useState<string | null>(null);
  const [editMode, setEditMode] = useState<Partial<UserProfile>>({});
  const [loading, setLoading] = useState(true);
  const [, setProfileImage] = useState<File | null>(null);
  const navigate = useNavigate();

  // Get userId, isAdmin, and isBusinessActivated from the token
  const { userId, isAdmin, isBusinessActivated } = getUserInfoFromToken();

  useEffect(() => {
    if (userId) {
      fetchUserProfile();
      fetchProfilePictureUrl();
    } else {
      console.error('User ID not found in token');
      setLoading(false);
    }
  }, [userId]);

  const fetchUserProfile = async () => {
    try {
      const authToken = localStorage.getItem('jwtToken');
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Auth/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setUser(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setLoading(false);
    }
  };

  const fetchProfilePictureUrl = async () => {
    try {
      const authToken = localStorage.getItem('jwtToken');
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Auth/profile-picture/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setProfilePicUrl(response.data.profilePicUrl);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setProfileImage(file);

      const formData = new FormData();
      formData.append('file', file);

      try {
        const authToken = localStorage.getItem('jwtToken');

        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/Auth/upload-profile-picture/${userId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        toast.success('Profile picture updated successfully!');
        fetchProfilePictureUrl();
      } catch (error) {
        console.error('Error uploading profile picture:', error);

        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          if (axiosError.response) {
            toast.error(
              `Failed to update profile picture: ${axiosError.response.status} - ${axiosError.response.statusText}`
            );
          } else if (axiosError.request) {
            toast.error('Failed to update profile picture: No response received from server');
          } else {
            toast.error(`Failed to update profile picture: ${axiosError.message}`);
          }
        } else {
          console.error('Non-Axios error:', error);
          toast.error('An unexpected error occurred while updating the profile picture');
        }
      }
    }
  };

  const handleEdit = (field: keyof UserProfile) => {
    setEditMode({ ...editMode, [field]: !editMode[field] });
  };

  const handleChange = (field: keyof UserProfile, value: string) => {
    if (user) {
      setUser({ ...user, [field]: value });
    }
  };

  const handleUpdate = async () => {
    if (!user) return;

    try {
      const authToken = localStorage.getItem('jwtToken');
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/Auth/update/${userId}`,
        user,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success('Profile updated successfully!');
      setEditMode({});
      fetchUserProfile();
    } catch (error) {
      console.error('Error updating profile:', error);

      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          toast.error(
            `Failed to update profile: ${axiosError.response.status} - ${axiosError.response.statusText}`
          );
        } else if (axiosError.request) {
          toast.error('Failed to update profile: No response received from server');
        } else {
          toast.error(`Failed to update profile: ${axiosError.message}`);
        }
      } else {
        console.error('Non-Axios error:', error);
        toast.error('An unexpected error occurred while updating the profile');
      }
    }
  };

  if (loading) {
    return (
      <>
        <Header />
        <div className="profile-loading-container">
          <div className="profile-loading-spinner"></div>
          <p>Loading ...</p>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <div className="profile-page-container">
      <Header />
      <div className="profile-page">
        <div className="profile-container">
          {/* Profile Card */}
          <div className="profile-card">
            <div className="profile-image-section">
              <div className="profile-image-container">
                <img
                  src={
                    profilePicUrl?.startsWith('http')
                      ? profilePicUrl
                      : `${process.env.REACT_APP_API_BASE_URL}${profilePicUrl}`
                  }
                  alt="Profile"
                  className="profile-image"
                />
                <label htmlFor="profile-picture-input" className="profile-image-upload-label">
                  <Camera size={24} />
                </label>
                <input
                  id="profile-picture-input"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                />
              </div>
              <h2 className="profile-name">{user?.fullName || user?.username}</h2>
              {isAdmin && <p className="admin-label">Admin User</p>}
            </div>

            <div className="profile-details">
              {Object.entries(user || {}).map(([key, value]) => {
                if (key === 'id' || key === 'profilePic') return null;
                return (
                  <div key={key} className="profile-detail-row">
                    <span className="profile-detail-label">
                      {key.charAt(0).toUpperCase() + key.slice(1)}:
                    </span>
                    {editMode[key as keyof UserProfile] ? (
                      <input
                        type={key === 'email' ? 'email' : 'text'}
                        value={value || ''}
                        onChange={(e) => handleChange(key as keyof UserProfile, e.target.value)}
                        className="profile-detail-input"
                      />
                    ) : (
                      <span className="profile-detail-value">{value || 'N/A'}</span>
                    )}
                    <button
                      onClick={() => handleEdit(key as keyof UserProfile)}
                      className="profile-edit-button"
                    >
                      <Edit2 size={16} />
                    </button>
                  </div>
                );
              })}
            </div>

            <div className="profile-save-button-container">
              <button onClick={handleUpdate} className="profile-save-button">
                <Save size={16} style={{ marginRight: '8px' }} /> Save Changes
              </button>
            </div>
          </div>

          {/* Action Cards Container */}
          <div className="action-cards-container">
            <div className="action-card" onClick={() => navigate('/manage-address')}>
              <Edit2 size={48} />
              <h2>Manage Address</h2>
            </div>

            <div className="action-card" onClick={() => navigate('/manage-payments')}>
              <Clock size={48} />
              <h2>View Pending Payments</h2>
            </div>

              {/* Reviews Button */}
  <div className="action-card" onClick={() => navigate('/reviews')}>
    <Edit2 size={48} />
    <h2>Reviews</h2>
  </div>

            {/* Conditionally render based on isAdmin and isBusinessActivated */}
            {isAdmin ? (
              <div className="action-card" onClick={() => navigate('/admin-page')}>
                <Shield size={48} />
                <h2>Access Admin Control Panel</h2>
              </div>
            ) : isBusinessActivated === 'Yes' ? (
              <div className="action-card" onClick={() => navigate('/business-dashboard')}>
                <Briefcase size={48} />
                <h2>Manage Business Profile</h2>
              </div>
            ) : (
              <div className="action-card" onClick={() => navigate('/ActivateBusinessAccount')}>
                <Briefcase size={48} />
                <h2>Become a Seller</h2>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Profile;
