// src/components/Admin/Payments/ProcessUserPayments.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../style/Payment/ProcessUserPayments.css';
import Footer from '../../Header and Footer/Footer';
import Header from '../../Header and Footer/Header';
import { jwtDecode } from "jwt-decode";

interface Payment {
  id: number;
  userId: number;
  paymentReference: string;
  totalPrice: number;
  status: string;
  createdAt: string;
  lastUpdatedBy?: string;
  lastUpdatedAt?: string;
  lastUpdatedComments?: string;
  proofOfPayment?: string;
}

interface DecodedToken {
  userId: string;
  isAdmin: string; // "True" or "False" in your JWT
  [key: string]: any;
}

// Function to get userId and isAdmin from the JWT token
const getUserInfoFromToken = (): { userId: string | null; isAdmin: boolean } => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return { userId: null, isAdmin: false };

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    const isAdmin = decodedToken.isAdmin === "True"; // Check the isAdmin field for Admin access
    return { userId: decodedToken.userId, isAdmin };
  } catch (error) {
    console.error('Failed to decode token:', error);
    return { userId: null, isAdmin: false };
  }
};

const ProcessUserPayments: React.FC = () => {
  // **Hooks at the Top Level**
  const [payments, setPayments] = useState<Payment[]>([]);
  const [selectedPaymentId, setSelectedPaymentId] = useState<number | null>(null);
  const [newStatus, setNewStatus] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const navigate = useNavigate();

  // Get userId and isAdmin from token
  const { userId, isAdmin } = getUserInfoFromToken();

  // Handle unauthorized access
  useEffect(() => {
    if (!isAdmin) {
      toast.error('You do not have permission to access this page.');
      navigate('/not-authorized');
    }
  }, [isAdmin, navigate]);

  // Fetch pending payments if user is admin
  useEffect(() => {
    if (isAdmin) {
      fetchPendingPayments();
    }
  }, [isAdmin]);

  const fetchPendingPayments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Payment/pending-payments`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
        }
      );
      setPayments(response.data);
    } catch (error) {
      toast.error('Error fetching payments');
      console.error('Error fetching payments:', error);
    }
  };

  const handleStatusChange = async () => {
    if (!selectedPaymentId || !newStatus) {
      toast.warning('Please select a payment and enter a status');
      return;
    }

    try {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        toast.error('User ID not found. Please log in.');
        return;
      }

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Payment/change-payment-status`,
        null, // No body content
        {
          params: {
            paymentId: selectedPaymentId,
            adminUserId: userId,
            newStatus,
            comments,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      toast.success('Payment status updated successfully');
      fetchPendingPayments(); // Refresh payment list after update
      setSelectedPaymentId(null); // Clear selected payment
      setNewStatus(''); // Clear status input
      setComments(''); // Clear comments
    } catch (error) {
      console.error('Error updating payment status:', error);
      toast.error('Error updating payment status');
    }
  };

  // **Conditional Rendering After Hooks**
  if (!isAdmin) {
    return null; // Prevent rendering if not admin
  }

  return (
    <>
      <Header />
      <div className="process-user-payments">
        <h1>Process User Payments</h1>
        <ToastContainer />

        <div className="payments-list">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Reference</th>
                <th>Total Price</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Last Updated By</th>
                <th>Last Updated At</th>
                <th>Proof of Payment</th>
                <th>Comments</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.id}</td>
                  <td>{payment.paymentReference}</td>
                  <td>R{payment.totalPrice.toFixed(2)}</td>
                  <td>{payment.status}</td>
                  <td>{new Date(payment.createdAt).toLocaleString()}</td>
                  <td>{payment.lastUpdatedBy || 'Not Available'}</td>
                  <td>
                    {payment.lastUpdatedAt
                      ? new Date(payment.lastUpdatedAt).toLocaleString()
                      : 'Not Available'}
                  </td>
                  <td>
                    {payment.proofOfPayment ? (
                      <a
                        href={payment.proofOfPayment}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#333', fontWeight: 'bold' }}
                      >
                        Download POP
                      </a>
                    ) : (
                      <span style={{ color: '#333' }}>Not Available</span>
                    )}
                  </td>
                  <td>{payment.lastUpdatedComments || 'Not Available'}</td>
                  <td>
                    <input
                      type="radio"
                      name="selectedPayment"
                      onChange={() => setSelectedPaymentId(payment.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Conditionally render the update section only if a payment is selected */}
        {selectedPaymentId && (
          <div className="status-update-section">
            <h2>Update Payment Status</h2>
            <label>
              New Status:
              <input
                type="text"
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
                placeholder="Enter new status"
              />
            </label>
            <label>
              Comments:
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                placeholder="Optional comments"
              />
            </label>
            <button onClick={handleStatusChange}>Update Status</button>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ProcessUserPayments;
