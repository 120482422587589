// src/components/Sell/Sell.tsx
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/Sell/Sell.css';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import { jwtDecode } from "jwt-decode";

interface BookData {
  name: string;
  description: string;
  genre: string;
  author: string;
  price: string;
  isbn: string;
  quantity: number;
  userId: string;
  imageUrl?: string;
  condition: string;
  isTextbook: boolean;
}

interface DecodedToken {
  userId: string;
  [key: string]: any;
}

// Input sanitization function
const sanitizeInput = (input: string): string => {
  // Remove leading and trailing whitespace
  let sanitizedInput = input.trim();
  // Remove any HTML tags
  sanitizedInput = sanitizedInput.replace(/<[^>]*>/g, '');
  return sanitizedInput;
};

const conditionOptions = [
  { value: 'New', label: 'New' },
  { value: 'Excellent', label: 'Excellent' },
  { value: 'Good', label: 'Good' },
  { value: 'Fair', label: 'Fair' },
  { value: 'Poor', label: 'Poor' },
];


// Sample genres for the dropdown
const genreOptions = [
  { value: 'Science Fiction', label: 'Science Fiction' },
  { value: 'Fantasy', label: 'Fantasy' },
  { value: 'Mystery', label: 'Mystery' },
  { value: 'Non-fiction', label: 'Non-fiction' },
  { value: 'Romance', label: 'Romance' },
  { value: 'Horror', label: 'Horror' },
  { value: 'Thriller', label: 'Thriller' },
  { value: 'Historical Fiction', label: 'Historical Fiction' },
  { value: 'Biography', label: 'Biography' },
  { value: 'Self-help', label: 'Self-help' },
  { value: 'Young Adult', label: 'Young Adult' },
  { value: "Children's", label: "Children's" },
  { value: 'Adventure', label: 'Adventure' },
  { value: 'Dystopian', label: 'Dystopian' },
  { value: 'Memoir', label: 'Memoir' },
  { value: 'Graphic Novel', label: 'Graphic Novel' },
  { value: 'Poetry', label: 'Poetry' },
  { value: 'Classic', label: 'Classic' },
  { value: 'True Crime', label: 'True Crime' },
  { value: 'Humour', label: 'Humour' },
  { value: 'Philosophy', label: 'Philosophy' },
  { value: 'Religion', label: 'Religion' },
  { value: 'Cookbook', label: 'Cookbook' },
  { value: 'Art', label: 'Art' },
  { value: 'Music', label: 'Music' },
  { value: 'Travel', label: 'Travel' },
  { value: 'Science', label: 'Science' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Health', label: 'Health' },
  { value: 'Business', label: 'Business' },
  { value: 'Education', label: 'Education' },
  { value: 'Psychology', label: 'Psychology' },
  { value: 'Political Science', label: 'Political Science' },
  // South African-specific genres
  { value: 'African Literature', label: 'African Literature' },
  { value: 'South African Fiction', label: 'South African Fiction' },
  { value: 'Apartheid Memoirs', label: 'Apartheid Memoirs' },
  { value: 'Indigenous Stories', label: 'Indigenous Stories' },
  { value: 'African Mythology', label: 'African Mythology' },
  { value: 'South African History', label: 'South African History' },
  { value: 'Social Justice', label: 'Social Justice' },
  { value: 'Political Memoirs', label: 'Political Memoirs' },
  { value: 'Urban Fiction', label: 'Urban Fiction' },
  { value: 'African Folktales', label: 'African Folktales' },
  { value: 'African Spirituality', label: 'African Spirituality' },
  { value: 'Afrikaans Literature', label: 'Afrikaans Literature' },
  { value: 'Zulu Literature', label: 'Zulu Literature' },
  { value: 'Xhosa Literature', label: 'Xhosa Literature' },
  { value: 'Historical Biographies', label: 'Historical Biographies' },
  { value: 'South African Poetry', label: 'South African Poetry' },
  { value: 'Contemporary African Fiction', label: 'Contemporary African Fiction' },
  { value: 'Political Satire', label: 'Political Satire' },
  { value: 'African Environmental Studies', label: 'African Environmental Studies' },
  { value: 'Social Issues', label: 'Social Issues' },
  { value: 'Natural Science', label: 'Natural Science' },
  { value: 'Environmental Science', label: 'Environmental Science' },
  { value: 'Astronomy', label: 'Astronomy' },
  { value: 'Botany', label: 'Botany' },
  { value: 'Zoology', label: 'Zoology' },
  { value: 'Entomology', label: 'Entomology' },
  { value: 'Geology', label: 'Geology' },
  { value: 'Marine Biology', label: 'Marine Biology' },
  { value: 'Physics', label: 'Physics' },
  { value: 'Chemistry', label: 'Chemistry' },
  { value: 'Biology', label: 'Biology' },
  { value: 'Ecology', label: 'Ecology' },
  { value: 'Paleontology', label: 'Paleontology' },
  { value: 'Anthropology', label: 'Anthropology' },

  // Additional Genres
  { value: 'Science and Technology', label: 'Science and Technology' },
  { value: 'Mythology', label: 'Mythology' },
  { value: 'Speculative Fiction', label: 'Speculative Fiction' },
  { value: 'Short Stories', label: 'Short Stories' },
  { value: 'Epics', label: 'Epics' },
  { value: 'LGBTQ+', label: 'LGBTQ+' },
  { value: 'Cultural Studies', label: 'Cultural Studies' },
  { value: 'War Stories', label: 'War Stories' },
  { value: 'Cyberpunk', label: 'Cyberpunk' },
  { value: 'Steampunk', label: 'Steampunk' },
  { value: 'Dark Fantasy', label: 'Dark Fantasy' },
  { value: 'Alternate History', label: 'Alternate History' },
  { value: 'Crime Fiction', label: 'Crime Fiction' },
  { value: 'Literary Fiction', label: 'Literary Fiction' },
  { value: 'Paranormal', label: 'Paranormal' },
  { value: 'Epic Fantasy', label: 'Epic Fantasy' },
  { value: 'Utopian', label: 'Utopian' },

  // Academic and Educational
  { value: 'Textbooks', label: 'Textbooks' },
  { value: 'Research Papers', label: 'Research Papers' },
  { value: 'Technical Manuals', label: 'Technical Manuals' },
  { value: 'Mathematics', label: 'Mathematics' },
  { value: 'Engineering', label: 'Engineering' },

  // South African and African-Specific
  { value: 'Traditional African Medicine', label: 'Traditional African Medicine' },
  { value: 'African Science', label: 'African Science' },
  { value: 'South African Travel', label: 'South African Travel' },
  { value: 'Indigenous Knowledge Systems', label: 'Indigenous Knowledge Systems' },
  { value: 'South African Art', label: 'South African Art' },

  // Miscellaneous
  { value: 'Inspirational', label: 'Inspirational' },
  { value: 'Trivia', label: 'Trivia' },
  { value: 'Essays', label: 'Essays' },
  { value: 'Satire', label: 'Satire' },

  // New academic genres (Only these new entries)
{ value: 'Statistics', label: 'Statistics' },
{ value: 'Calculus', label: 'Calculus' },
{ value: 'Business Management', label: 'Business Management' },
{ value: 'Website development', label: 'Website development' },
{ value: 'Computer Science', label: 'Computer Science' },
{ value: 'Data Science', label: 'Data Science' },
{ value: 'Dictionary', label: 'Dictionary' },
{ value: 'Information Technology', label: 'Information Technology' },
{ value: 'Economics', label: 'Economics' },
{ value: 'Accounting', label: 'Accounting' },
{ value: 'Marketing', label: 'Marketing' },
{ value: 'Finance', label: 'Finance' },
{ value: 'Architecture', label: 'Architecture' },
{ value: 'Law', label: 'Law' },
{ value: 'Medicine', label: 'Medicine' },
{ value: 'Nursing', label: 'Nursing' },
{ value: 'Physiotherapy', label: 'Physiotherapy' },
{ value: 'Social Work', label: 'Social Work' },
{ value: 'Journalism', label: 'Journalism' },
{ value: 'Media Studies', label: 'Media Studies' },
{ value: 'Sociology', label: 'Sociology' },
{ value: 'Languages', label: 'Languages' },
{ value: 'Literary Studies', label: 'Literary Studies' },
{ value: 'Pharmacy', label: 'Pharmacy' },

];

const getUserIdFromToken = (): string | null => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.userId;
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};

const Sell: React.FC = () => {
  const initialBookData: BookData = {
    name: '',
    description: '',
    genre: '',
    author: '',
    price: '',
    isbn: '',
    quantity: 1,
    userId: getUserIdFromToken() || '',
    imageUrl: '',
    condition: '',
    isTextbook: false,
  };

  
  const [bookData, setBookData] = useState<BookData>(initialBookData);
  const [image, setImage] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const userId = getUserIdFromToken();
    if (userId) {
      setBookData((prevData) => ({
        ...prevData,
        userId: userId,
      }));
    } else {
      toast.error('User ID not found. Please log in again.');
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setBookData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle genre selection change
  const handleGenreChange = (selectedOption: any) => {
    setBookData((prevData) => ({
      ...prevData,
      genre: selectedOption ? selectedOption.value : '',
    }));
  };

   // Handle condition selection change
   const handleConditionChange = (selectedOption: any) => {
    setBookData((prevData) => ({
      ...prevData,
      condition: selectedOption ? selectedOption.value : '',
    }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (!file.type.startsWith('image/')) {
        toast.error('Please upload an image file');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        toast.error('Image size should be less than 5MB');
        return;
      }
      setImage(file);
    }
  };

  const validateForm = (): boolean => {
    if (!bookData.name.trim()) {
      toast.error('Please enter a book title');
      return false;
    }
    if (!bookData.author.trim()) {
      toast.error('Please enter an author name');
      return false;
    }
    if (!bookData.price || isNaN(parseFloat(bookData.price)) || parseFloat(bookData.price) <= 0) {
      toast.error('Please enter a valid price');
      return false;
    }
    if (!bookData.condition) {
      toast.error('Please select a condition');
      return false;
    }
    if (!bookData.description.trim()) {
      toast.error('Please enter a description');
      return false;
    }
    if (!bookData.quantity || bookData.quantity < 1) {
      toast.error('Please enter a valid quantity');
      return false;
    }
    if (!image && !bookData.imageUrl) {
      toast.error('Please upload an image or provide an image URL');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!validateForm() || isSubmitting) {
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const sanitizedBookData: BookData = {
        ...bookData,
        name: sanitizeInput(bookData.name),
        author: sanitizeInput(bookData.author),
        genre: sanitizeInput(bookData.genre),
        isbn: sanitizeInput(bookData.isbn),
        price: sanitizeInput(bookData.price),
        description: sanitizeInput(bookData.description),
        imageUrl: sanitizeInput(bookData.imageUrl || ''),
        // `isTextbook` doesn't need sanitization as it's a boolean
      };
  
      const formData = new FormData();
  
      Object.entries(sanitizedBookData).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
          formData.append(key, value.toString());
        }
      });
  
      if (image) {
        formData.append('Image', image);
      }
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Category`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );
  
      if (response.status === 201) {
        toast.success('Book listed for sale successfully!');
        setBookData({
          ...initialBookData,
          userId: bookData.userId,
        });
        setImage(null);
        if (document.getElementById('image') instanceof HTMLInputElement) {
          (document.getElementById('image') as HTMLInputElement).value = '';
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error.response?.data || 'Failed to list the book';
        toast.error(message);
      } else {
        toast.error('An unexpected error occurred');
      }
      console.error('Error selling book:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <>
      <Header />
    

      <div className="tutorial-link">
  <p>
    Need help listing books? Watch our quick tutorial on YouTube:  
    <a 
      href="https://youtu.be/1JIObqxI1z4" 
      target="_blank" 
      rel="noopener noreferrer" 
      className="tutorial-link-anchor"
    >
      Watch Tutorial
    </a>
  </p>
</div>


      <div className="sell-page-container">
        <div className="sell-form-wrapper">
          <h1 className="page-title">Sell a Book</h1>
          <form className="sell-form" onSubmit={handleSubmit}>
            <div className="form-section">
              <h2>Book Details</h2>
              <div className="form-group">
                <label htmlFor="name">Book Title *</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={bookData.name}
                  onChange={handleInputChange}
                  placeholder="Enter book title"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="author">Author *</label>
                <input
                  type="text"
                  id="author"
                  name="author"
                  value={bookData.author}
                  onChange={handleInputChange}
                  placeholder="Enter author name"
                  required
                />
              </div>

              <div className="form-group">
  <label htmlFor="isTextbook">Is this a textbook?</label>
  <select
    id="isTextbook"
    name="isTextbook"
    value={bookData.isTextbook ? "Yes" : "No"}
    onChange={(e) =>
      setBookData((prevData) => ({
        ...prevData,
        isTextbook: e.target.value === "Yes",
      }))
    }
  >
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</div>




              <div className="form-group">
                <label htmlFor="genre">Genre *</label>
                <Select
                  options={genreOptions}
                  value={genreOptions.find((option) => option.value === bookData.genre)}
                  onChange={handleGenreChange}
                  placeholder="Select a genre"
                  isClearable
                />
              </div>

              <div className="form-group">
                <label htmlFor="isbn">ISBN</label>
                <input
                  type="text"
                  id="isbn"
                  name="isbn"
                  value={bookData.isbn}
                  onChange={handleInputChange}
                  placeholder="Enter ISBN"
                />
              </div>

          {/* Existing Form Elements */}
      <div className="form-group">
        <label htmlFor="price">Price (ZAR) *</label>
        <input
          type="number"
          step="0.01" // Allow decimals
          min="0"
          id="price"
          name="price"
          value={bookData.price}
          onChange={handleInputChange}
          placeholder="Enter the price"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="condition">Condition *</label>
        <Select
          options={conditionOptions}
          value={conditionOptions.find((option) => option.value === bookData.condition)}
          onChange={handleConditionChange}
          placeholder="Select condition"
          isClearable
        />
      </div>

              <div className="form-group">
                <label htmlFor="quantity">Quantity *</label>
                <input
                  type="number"
                  min="1"
                  id="quantity"
                  name="quantity"
                  value={bookData.quantity}
                  onChange={handleInputChange}
                  placeholder="Enter quantity"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="description">Description *</label>
                <textarea
                  id="description"
                  name="description"
                  value={bookData.description}
                  onChange={handleInputChange}
                  placeholder="Enter book description"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="image">Upload Book Image *</label>
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={handleImageChange}
                  required={!bookData.imageUrl}
                />
                <small>Maximum file size: 5MB. Supported formats: JPG, PNG, GIF</small>
              </div>

         {/*   <div className="form-group">
                <label htmlFor="imageUrl">Or Image URL</label>
                <input
                  type="text"
                  id="imageUrl"
                  name="imageUrl"
                  value={bookData.imageUrl}
                  onChange={handleInputChange}
                  placeholder="Enter image URL (optional if uploading image)"
                />
              </div> */}

              <button type="submit" className="sell-button" disabled={isSubmitting}>
                {isSubmitting ? 'Listing Book...' : 'List Book'}
              </button>
            </div>
            <ToastContainer position="top-right" autoClose={5000} />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Sell;
