import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header and Footer/Header';
import Footer from '../Header and Footer/Footer';
import '../../style/Reviews/ReviewsPage.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from "jwt-decode";
import ReactStars from 'react-rating-stars-component';
import DOMPurify from 'dompurify';

interface Review {
  id: number;
  userId: number;
  serviceId: number;
  rating: number;
  comment: string;
  dateCreated: string;
}

interface DecodedToken {
  userId: string;
  securityCode: string;
  [key: string]: any;
}

// Helper function to retrieve user data from the JWT in local storage
const getUserDataFromToken = (): { userId: string | null; securityCode: string | null } => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return { userId: null, securityCode: null };

  try {
    const decodedToken: DecodedToken = jwtDecode(token);
    return {
      userId: decodedToken.userId || null,
      securityCode: decodedToken.securityCode || null,
    };
  } catch (error) {
    console.error('Failed to decode token:', error);
    return { userId: null, securityCode: null };
  }
};

const ReviewsPage: React.FC = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState(true);
  const [newReview, setNewReview] = useState({
    serviceId: 1, // default to "General Feedback" ID
    rating: 0,    // numeric rating (can be half-star)
    comment: ''
  });
  const [error, setError] = useState('');

  // Fetch existing reviews for the logged-in user
  const fetchReviews = async () => {
    const { userId, securityCode } = getUserDataFromToken();
    if (!userId || !securityCode) {
      setError('User is not logged in.');
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Review/general-feedback/user/${userId}`,
        {
          params: { securityCode },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );
      setReviews(response.data);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle adding a new review
  const handleAddReview = async () => {
    const { userId, securityCode } = getUserDataFromToken();
    if (!userId || !securityCode) {
      toast.error('User is not logged in.');
      return;
    }

    // Basic sanitization for rating
    if (newReview.rating < 0.5 || newReview.rating > 5) {
      toast.error('Please provide a rating between 0.5 and 5.');
      return;
    }

    try {
      // Send to server
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Review`,
        { ...newReview, userId },
        {
          params: { securityCode },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );

      toast.success('Review added successfully!');
      setNewReview({ serviceId: 1, rating: 0, comment: '' }); // Reset form
      fetchReviews(); // Refresh list
    } catch (error) {
      console.error('Error adding review:', error);
      toast.error('Failed to add review. Please try again.');
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  // Star rating configuration
  const starRatingConfig = {
    size: 30,
    count: 5,
    isHalf: true, // Allow half stars
    value: newReview.rating,
    color: 'gray',
    activeColor: '#ffd700',
    onChange: (newValue: number) => {
      setNewReview((prev) => ({ ...prev, rating: newValue }));
    },
  };

  // Handle comment input change with basic HTML sanitization via DOMPurify
  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const sanitized = DOMPurify.sanitize(e.target.value);
    setNewReview((prev) => ({ ...prev, comment: sanitized }));
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading Reviews...</p>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="reviews-page-container">
        <h1 className="page-title">User Reviews</h1>
        {error && <p className="error-message">{error}</p>}

        {/* Add a new Review - Placed first for a more modern flow */}
        <div className="add-review-section">
          <h2 className="section-title">Add a Review</h2>

          <div className="review-form">
            <label className="label" htmlFor="service-select">Service</label>
            <select
              id="service-select"
              className="dropdown"
              value={newReview.serviceId}
              onChange={(e) =>
                setNewReview({ ...newReview, serviceId: Number(e.target.value) })
              }
            >
              {/* Add more options here as you expand services */}
              <option value={1}>General Feedback</option>
            </select>

            <label className="label" style={{ marginTop: '1rem' }}>Rating</label>
            <div className="star-rating-container">
              <ReactStars {...starRatingConfig} />
            </div>

            <label className="label" htmlFor="comment-textarea">Review Comment</label>
            <textarea
              id="comment-textarea"
              className="comment-box"
              placeholder="Write your review here..."
              value={newReview.comment}
              onChange={handleCommentChange}
            />

            <button className="submit-btn" onClick={handleAddReview}>Submit Review</button>
          </div>
        </div>

        {/* Existing Reviews - Placed below the new review section */}
        <div className="reviews-list">
          <h2 className="section-title" style={{ marginTop: '2rem' }}>Previous Reviews</h2>
          {reviews.length > 0 ? (
            reviews.map((review) => (
              <div key={review.id} className="review-card">
                <p>
                  <strong>Service ID:</strong> {review.serviceId}
                </p>
                <p>
                  <strong>Rating:</strong> {review.rating}
                </p>
                <p>
                  <strong>Comment:</strong> {review.comment || 'No comment'}
                </p>
                <p>
                  <strong>Date:</strong> {new Date(review.dateCreated).toLocaleDateString()}
                </p>
              </div>
            ))
          ) : (
            <p className="no-reviews">No reviews found.</p>
          )}
        </div>
      </div>

      <Footer />
      <ToastContainer />
    </>
  );
};

export default ReviewsPage;
