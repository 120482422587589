// src/components/Admin/UserManagement/UserManagement.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../style/Admin/User management/UserManagement.css';
import Header from '../../Header and Footer/Header';
import Footer from '../../Header and Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

interface User {
  id: number;
  fullName: string;
  username: string;
  email: string;
  cellphone: string;
  isBusinessActivated: string;
}

interface DecodedToken {
  userId: string;
  isAdmin: string; // "True" or "False" in your JWT
  [key: string]: any;
}

const UserManagement: React.FC = () => {
  // **Hooks at the Top Level**
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // **Extract isAdmin from JWT token**
  const token = localStorage.getItem('jwtToken');
  let isAdminUser = false;

  if (token) {
    try {
      const decodedToken = jwtDecode<DecodedToken>(token);
      // Convert the string "True"/"False" to a proper boolean
      isAdminUser = decodedToken.isAdmin === 'True';
    } catch (error) {
      console.error('Failed to decode token:', error);
      navigate('/login');
    }
  } else {
    // If no token, redirect to login
    navigate('/login');
  }

  // **Handle unauthorized access inside useEffect**
  useEffect(() => {
    if (!isAdminUser) {
      toast.error('You do not have permission to access this page.');
      navigate('/not-authorized');
    }
  }, [isAdminUser, navigate]);

  // **Fetch users if admin**
  useEffect(() => {
    if (isAdminUser) {
      fetchUsers();
    }
  }, [isAdminUser]);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        toast.error('Authentication token not found. Please log in.');
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Auth/all-users`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setUsers(response.data);
    } catch (error) {
      toast.error('Failed to fetch users.');
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const suspendUser = async (userId: number) => {
    try {
      const token = localStorage.getItem('jwtToken');
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/Auth/suspend-user/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      toast.success('User suspended successfully.');
      fetchUsers(); // Refresh the user list to reflect the change
    } catch (error) {
      console.error('Error suspending user:', error);
      toast.error('Failed to suspend user.');
    }
  };

  // **Conditional Rendering After Hooks**
  if (!isAdminUser) {
    return null; // Explicitly return null instead of undefined
  }

  return (
    <div className="user-management-page">
      <Header />
      <ToastContainer />
      <main className="user-management-content">
        <h1 className="page-title">User Management</h1>
        {loading ? (
          <div className="loading">
            <p>Loading users...</p>
          </div>
        ) : users.length > 0 ? (
          <div className="user-table-container">
            <table className="user-table">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>ID</th>
                  <th>Full Name</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Cellphone</th>
                  <th>Business Activated</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <button
                        className="suspend-button"
                        onClick={() => suspendUser(user.id)}
                      >
                        Suspend
                      </button>
                    </td>
                    <td>{user.id}</td>
                    <td>{user.fullName}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{user.cellphone || 'N/A'}</td>
                    <td>{user.isBusinessActivated === 'Yes' ? 'Yes' : 'No'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="no-users">No users found.</p>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default UserManagement;
