import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../Header and Footer/Header';
import Footer from '../../Header and Footer/Footer';
import '../../../style/Admin/Announce/AnnouncePage.css';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  userId: string;
  isAdmin: string; // "True" or "False" in your JWT
  [key: string]: any;
}

// Function to get userId and isAdmin from the JWT token
const getUserInfoFromToken = (): { userId: string | null; isAdmin: boolean } => {
  const token = localStorage.getItem('jwtToken');
  if (!token) return { userId: null, isAdmin: false };

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    const isAdmin = decodedToken.isAdmin === "True"; // Convert "True" or "False" to boolean
    return { userId: decodedToken.userId, isAdmin };
  } catch (error) {
    console.error('Failed to decode token:', error);
    return { userId: null, isAdmin: false };
  }
};

const Announce: React.FC = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  const navigate = useNavigate();

  // Get userId and isAdmin from token
  const { userId, isAdmin } = getUserInfoFromToken();

  // Handle unauthorized access
  useEffect(() => {
    if (!isAdmin) {
      toast.error('You do not have permission to access this page.');
      navigate('/not-authorized');
    }
  }, [isAdmin, navigate]);

  // Prevent rendering if not admin
  if (!isAdmin) {
    return null;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!subject || !message) {
      toast.error('Please fill in both subject and message before sending.');
      return;
    }

    try {
      setIsSending(true);

      const token = localStorage.getItem('jwtToken');
      if (!token) {
        toast.error('User not authenticated. Please log in.');
        navigate('/login');
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/send-announcement`,
        {
          subject,
          message,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success('Announcement sent successfully!');
        setSubject('');
        setMessage('');
      } else {
        toast.error('Failed to send announcement. Please try again.');
      }
    } catch (error) {
      console.error('Error sending announcement:', error);
      toast.error('An error occurred while sending the announcement.');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Header />
      <div className="announce-page-container">
        <div className="announce-form-wrapper">
          <h1 className="page-title">Send Announcement</h1>
          <p className="description">
            Use this form to send an announcement to all active users. Fill in the subject and message, and click "Send
            Announcement" to notify users.
          </p>
          <form onSubmit={handleSubmit} className="announce-form">
            {/* Subject */}
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Enter the subject"
                required
              />
            </div>

            {/* Message */}
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter the message"
                rows={6}
                required
              ></textarea>
            </div>

            {/* Submit Button */}
            <button type="submit" className="submit-button" disabled={isSending}>
              {isSending ? 'Sending...' : 'Send Announcement'}
            </button>
          </form>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default Announce;
