import React, { ButtonHTMLAttributes } from 'react';
import { Lock, Home, Mail } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'outline';
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ 
  children, 
  variant = 'default', 
  className = '', 
  ...props 
}) => (
  <button
    className={`button ${variant === 'default' ? 'button-primary' : 'button-outline'} ${className}`}
    {...props}
  >
    {children}
  </button>
);

const NotAuthorized: React.FC = () => {
  // Add CSS to the document
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .not-authorized-container {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        background: linear-gradient(to bottom right, #f0f7ff, #e8eeff);
      }

      .error-card {
        max-width: 32rem;
        width: 100%;
        background: white;
        border-radius: 12px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 2rem;
      }

      .icon-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-bottom: 2rem;
      }

      .icon-wrapper {
        position: relative;
      }

      .icon-background {
        background-color: #eef2ff;
        border-radius: 50%;
        padding: 1rem;
      }

      @keyframes ping {
        75%, 100% {
          transform: scale(2);
          opacity: 0;
        }
      }

      .icon-ping {
        position: absolute;
        inset: 0;
        border-radius: 50%;
        background-color: #eef2ff;
        animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
      }

      .divider {
        height: 4px;
        width: 5rem;
        background-color: #4f46e5;
        border-radius: 2px;
        margin: 1rem auto;
      }

      .text-content {
        text-align: center;
        margin-bottom: 2rem;
      }

      .title {
        font-size: 2.25rem;
        font-weight: bold;
        color: #111827;
        margin: 0;
      }

      .message {
        font-size: 1.125rem;
        color: #4b5563;
        margin: 1rem 0;
      }

      .submessage {
        color: #6b7280;
        margin: 0.5rem 0;
      }

      .button-container {
        display: flex;
        gap: 1rem;
        justify-content: center;
        margin-bottom: 1.5rem;
      }

      .button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        font-weight: 500;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s;
        border: none;
      }

      .button-primary {
        background-color: #4f46e5;
        color: white;
      }

      .button-primary:hover {
        background-color: #4338ca;
      }

      .button-outline {
        background-color: white;
        color: #4b5563;
        border: 1px solid #d1d5db;
      }

      .button-outline:hover {
        background-color: #f9fafb;
      }

      .error-code {
        text-align: center;
        font-size: 0.875rem;
        color: #6b7280;
      }

      @media (max-width: 640px) {
        .button-container {
          flex-direction: column;
        }

        .button {
          width: 100%;
          justify-content: center;
        }
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div className="not-authorized-container">
      <div className="error-card">
        <div className="icon-container">
          <div className="icon-wrapper">
            <div className="icon-ping"></div>
            <div className="icon-background">
              <Lock className="h-12 w-12" color="#4f46e5" />
            </div>
          </div>
          
          <h1 className="title">Access Denied</h1>
          <div className="divider"></div>
        </div>

        <div className="text-content">
          <p className="message">
            Oops! It seems you don't have permission to access this page.
          </p>
          <p className="submessage">
            If you believe this is a mistake, please contact our support team.
          </p>
        </div>

        <div className="button-container">
  <Link to="/landingpage" className="button">
    <Home className="h-4 w-4" />
    Return Home
  </Link>
</div>

        <div className="error-code">
          Error 403 | Forbidden Access
        </div>
      </div>
    </div>
  );
};

export default NotAuthorized;