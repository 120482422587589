// src/components/Admin/UserPermissions.tsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../../Header and Footer/Header';
import Footer from '../../Header and Footer/Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../style/Admin/User management/UserPermissions.css';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { jwtDecode } from 'jwt-decode';

interface BusinessAccountRequest {
  id: number;
  userId: number;
  accountType: string;
  fullName: string;
  contactEmail: string;
  contactPhone: string;
  businessName?: string;
  registrationNumber?: string;
  taxId?: string;
  status: string;
}

interface DecodedToken {
  userId: string;
  securityCode: string;
  isAdmin: string; // "True" or "False" in your JWT
  [key: string]: any;
}

const UserPermissions: React.FC = () => {
  const [requests, setRequests] = useState<BusinessAccountRequest[]>([]);
  const navigate = useNavigate();

  // Retrieve JWT token from localStorage
  const token = localStorage.getItem('jwtToken');
  let isAdminUser = false;
  let decodedUserId: string | null = null;
  let decodedSecurityCode: string | null = null;

  // Decode the token to extract userId, securityCode, isAdmin, etc.
  if (token) {
    try {
      const decodedToken = jwtDecode<DecodedToken>(token);
      // Convert the string "True"/"False" to a boolean
      isAdminUser = decodedToken.isAdmin === 'True';
      decodedUserId = decodedToken.userId;
      decodedSecurityCode = decodedToken.securityCode;
    } catch (error) {
      console.error('Failed to decode token:', error);
      navigate('/login');
    }
  } else {
    // If no token, redirect to login
    navigate('/login');
  }

  // Handle unauthorized access in useEffect
  useEffect(() => {
    if (!isAdminUser) {
      toast.error('You do not have permission to access this page.');
      navigate('/not-authorized');
    }
  }, [isAdminUser, navigate]);

  // Fetch pending requests if admin
  useEffect(() => {
    if (isAdminUser) {
      fetchPendingRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminUser]);

  const fetchPendingRequests = async () => {
    if (!decodedUserId || !decodedSecurityCode) {
      toast.error('User information not found. Please log in again.');
      navigate('/login');
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/BusinessAccount/pending-requests?userId=${encodeURIComponent(
          decodedUserId
        )}&securityCode=${encodeURIComponent(decodedSecurityCode)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRequests(response.data);
    } catch (error) {
      toast.error('Failed to load pending requests');
      console.error('Error fetching pending requests:', error);
    }
  };

  const updateRequestStatus = async (id: number, status: string) => {
    if (!decodedUserId || !decodedSecurityCode) {
      toast.error('User information not found. Please log in again.');
      navigate('/login');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/BusinessAccount/update-status/${id}?userId=${encodeURIComponent(
          decodedUserId
        )}&securityCode=${encodeURIComponent(decodedSecurityCode)}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(`Request status updated to ${status}`);
      fetchPendingRequests();
    } catch (error) {
      toast.error('Failed to update request status');
      console.error('Error updating request status:', error);
    }
  };

  // Conditional Rendering After Hooks
  if (!isAdminUser) {
    return null; // Explicitly return null instead of undefined
  }

  return (
    <>
      <Header />
      <div className="user-permissions-container">
        <div className="user-permissions-content">
          <h1 className="page-title">User Permissions Management</h1>
          <ToastContainer />
          {requests.length === 0 ? (
            <p className="no-requests-message">No pending activation requests</p>
          ) : (
            <table className="permissions-table">
              <thead>
                <tr>
                  <th>Request ID</th>
                  <th>Full Name</th>
                  <th>Contact Email</th>
                  <th>Contact Phone</th>
                  <th>Account Type</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request) => (
                  <tr key={request.id}>
                    <td>{request.id}</td>
                    <td>{request.fullName}</td>
                    <td>{request.contactEmail}</td>
                    <td>{request.contactPhone}</td>
                    <td>{request.accountType}</td>
                    <td>{request.status}</td>
                    <td>
                      <button
                        onClick={() => updateRequestStatus(request.id, 'Approved')}
                        disabled={request.status === 'Approved'}
                        className="approve-button"
                        title="Approve"
                      >
                        <FaCheckCircle />
                      </button>
                      <button
                        onClick={() => updateRequestStatus(request.id, 'Rejected')}
                        disabled={request.status === 'Rejected'}
                        className="reject-button"
                        title="Reject"
                      >
                        <FaTimesCircle />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserPermissions;
