import React from 'react';
import { Link } from 'react-router-dom';
import '../../style/TermsAndConditions/TermsAndConditions.css';

const TermsAndConditions: React.FC = () => {
  return (
    <div className="terms-and-conditions">
      <header className="terms-header">
        <h1>Terms and Conditions</h1>
      </header>
      <main className="terms-content">
        <section>
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing and using this website, you agree to comply with and be bound by the following terms and conditions. If you do not agree with these terms, please do not use our website.
          </p>
        </section>

        <section>
          <h2>2. Use of Cookies</h2>
          <p>
            This website uses cookies to enhance your browsing experience. Cookies help us understand how you interact with our website and improve the services we offer. By using this website, you consent to our use of cookies as described in our{' '}
            <Link to="/privacy-policy" className="link">
              Privacy Policy
            </Link>.
          </p>
        </section>

        <section>
          <h2>3. IP Address Tracking</h2>
          <p>
            We track your IP address to improve your user experience, including providing personalized content and ensuring the security of our platform. This information is stored securely and used in accordance with our{' '}
            <Link to="/privacy-policy" className="link">
              Privacy Policy
            </Link>.
          </p>
        </section>

        <section>
          <h2>4. User Conduct</h2>
          <p>
            You agree to use this website only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit the use and enjoyment of this site by any third party. Prohibited behavior includes harassing or causing distress to any other user and transmitting obscene or offensive content.
          </p>
        </section>

        <section>
          <h2>5. Intellectual Property</h2>
          <p>
            All content on this website, including text, images, graphics, and logos, is the property of [Your Company Name] and is protected by applicable copyright and trademark laws. You may not reproduce, distribute, or modify any content without our explicit permission.
          </p>
        </section>

        <section>
          <h2>6. Disclaimer of Liability</h2>
          <p>
            While we strive to ensure the accuracy of the information on our website, we do not guarantee that all content is free from errors or omissions. [Your Company Name] will not be held liable for any damages arising from the use of this website.
          </p>
        </section>

        <section>
          <h2>7. Changes to These Terms</h2>
          <p>
            We reserve the right to update these terms and conditions at any time. Changes will be posted on this page, and your continued use of the website signifies your agreement to the updated terms.
          </p>
        </section>

        <section>
          <h2>8. Contact Us</h2>
          <p>
            If you have any questions about these terms and conditions, please contact us at{' '}
            <a href="mailto:thesecondhandbookdealer@gmail.com" className="link">
            thesecondhandbookdealer@gmail.com
            </a>
            .
          </p>
        </section>
      </main>
    </div>
  );
};

export default TermsAndConditions;
